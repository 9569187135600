// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-primary: (
  main: #b6123e,
  lighter: #e9b8c5,
  darker: #9e0a28,
  200: #b6123e,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$mat-accent: (
  main: #055069,
  lighter:  #859ac0,
  darker: #03374c,
  200: #055069,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-focused: (
  main: #055069,
  lighter:  #859ac0,
  darker: #03374c,
  200: #055069,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);

$vol-web-primary: mat.define-palette($mat-primary, main, lighter, darker);
$vol-web-accent: mat.define-palette($mat-accent, main, lighter, darker);
$vol-web-warn: mat.define-palette($mat-warn, main, lighter, darker);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$vol-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $vol-web-primary,
      accent: $vol-web-accent,
      warn: $vol-web-warn,
    ),
    is-dark: false,
  )
);

$vol-web-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $vol-web-primary,
      accent: $vol-web-accent,
      warn: $vol-web-warn,
    ),
    is-dark: true,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vol-web-theme);

.dark {
  @include mat.all-component-colors($vol-web-dark-theme);
}

:root {
  /* Colors: */
  --yellow_700: #b5ab24;
  --green_800: #378b22;
  --white: #ffffff;
  --grey_50: #f9f9f9;
  --grey_100: #f2f2f2;
  --grey_200: #eaeaea;
  --grey_300: #dadada;
  --grey_400: #b6b6b6;
  --grey_500: #979797;
  --grey_600: #6e6e6e;
  --grey_700: #5b5b5b;
  --grey_900: #1c1c1c;
  --secondary_50: #d3f1ff;
  --secondary_100: #aed7eb;
  --secondary_200: #8cb9d1;
  --secondary_500: #297190;
  --secondary_700: #055069;
  --primary_200: #f18a9c;
  --primary_300: #e95c76;
  --primary_400: #e13b5b;
  --primary_700: #b6123e;
}

body {
  --primary-color: #b6123e;
  --primary-lighter-color: #e9b8c5;
  --primary-darker-color: #9e0a28;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

body {
  --accent-color: #055069;
  --accent-lighter-color: #b4cbd2;
  --accent-darker-color: #03374c;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #5b5b5b;
}

.primary {
  color: #b6123e;
}

html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--grey_50);
  scrollbar-gutter: stable;
}

.with-watermark {
  z-index: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 70%;
  width: 100%;
  background-image: url("/assets/watermark.png");
  background-position: -50px calc(100% + 50px);
  background-size: contain;
  overflow: hidden;
  background-repeat: no-repeat, no-repeat;
}

.container {
  width: 100%;
  max-width: 102.5rem;
  min-width: 22rem;
  margin: auto;
}

.spacer {
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1px;
}

.navigation-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

tr {
  transition: all ease-in-out 0.2s;
}

button {
  transition: all ease-in-out .2s;
  background-color: #00000001;

  &:hover {
    background-color: #0001;
  }
}

::ng-deep {
  .mat-icon {
    color: #5B5B5B;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline, .mat-mdc-form-field-appearance-outline .mat-form-field-outline, .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline, .mat-form-field-appearance-outline .mat-mdc-form-field-outline {
     color: white !important;
  }
  mat-form-field {
     .mat-hint, input, ::placeholder, .mat-form-field-label {
        color: white !important;
     }
  }
}

::ng-deep.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color: cyan;
}
